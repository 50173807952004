import "bootstrap";
import "./modules/plyr";
import "./modules/tooltip";
import "./modules/countdown"
import "./modules/headroom";
import "./modules/tiny-slider";
import "./modules/prism";
import "./modules/aos";
import "./modules/navbar";
import "./modules/parallax";
import "./modules/big-picture";
import "./modules/isotope";
import "./modules/offcanvas";
import "./modules/forms";
import "./modules/nouislider";
import "./modules/counter";
import "./modules/typed";
import "./modules/smooth-scroll";